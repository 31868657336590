import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { URL, API, TOKEN } from './consts.js';

import Page from './components/Page.jsx';
import Footer from './components/Footer.jsx';

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }} />
  );
}

const Main = (props) => (
  <Router>
    <span>
      <Switch>
        <Redirect exact from="/" to="/etusivu" />
        <PropsRoute path='/:slug' component={Page} {...props} />
      </Switch>

      <section id="mezzanine" className="contact">
        <div className="row">

          <aside className="four columns footer-widgets contactdetails">
            {props.meta ?
            <div className="widget widget_contact">
              <h4>Käyntiosoite</h4>
              <p className="address">
                {props.meta.location}<br/>
                {props.meta.address_1}<br />
                {props.meta.address_2}<br />

                <br />
                  <i className="fa fa-map-marker" style={{ width: '0px', paddingLeft: '4px', marginRight: '22px'}}></i>
                <a href={props.meta.route_1}>Föli Reittiopas</a>
                &nbsp;|&nbsp;
                <a href={props.meta.route_2}>Opaskartta</a><br />
                <i className="fa fa-phone" style={{width: '0px', paddingLeft: '2px', marginRight: '24px'}}></i>{props.meta.phone}<br />
                <i className="fa fa-envelope" style={{width: '0px', marginRight: '26px'}}></i>{props.meta.email}<br />
              </p>
            </div>
            : ''}
          </aside>

          <aside className="four columns footer-widgets ig">
            <div className="widget widget_tweets">
              <div>
                {props.ig ?
                  <div id="portfolio-wrapper" className="bgrid-halves s-bgrid-halves cf">
                    {'edges' in props.ig && props.ig.edges.map((item, i) => {
                      var { node} = item
                      var shortcode = node.shortcode || 'BeUu2sIl6HY'
                      var thumbnail_src = node.thumbnail_src || 'https://scontent-hel2-1.cdninstagram.com/vp/ae16c361f66fe462776477790afebc3f/5E59ECDB/t51.2885-19/s320x320/26352053_402416216871214_2637032859440775168_n.jpg?_nc_ht=scontent-hel2-1.cdninstagram.com'

                      return <div key={i} className="columns portfolio-item">
                        <div className="item-wrap">
                          <a href={`https://www.instagram.com/p/${shortcode}/`} title="">
                            <img alt="" src={thumbnail_src} />
                            <div className="overlay">
                              <div className="portfolio-item-meta">
                              </div>
                            </div>
                            <div className="link-icon"><i className="icon-plus"></i></div>
                          </a>
                        </div>
                      </div>
                    })}
                    <a href={`https://www.instagram.com/${props.ig.name}/`}>
                      <img alt="Profile" className="ig-profile" src={props.ig.profile_pic} />
                    </a>
                    <div className="row text-center cf">
                      <h5>@{props.ig.name}</h5>
                      <p>{props.ig.followers} seuraajaa</p>
                      <a href={`https://www.instagram.com/${props.ig.name}/`} className="button"><i className="fa fa-instagram"></i>&ensp;Seuraa</a>
                    </div>
                  </div>
                : ''
                }
              </div>
            </div>
          </aside>

          <aside className="four columns footer-widgets fb">
            <div className="widget widget_tweets">
              <iframe referrerPolicy="no-referrer" name="f3dd30785549e4" width="299px" height="230px" frameBorder="0" allowtransparency="true" scrolling="no" title="fb:page Facebook Social Plugin" src="https://www.facebook.com/v2.12/plugins/page.php?adapt_container_width=true&amp;app_id=113869198637480&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2FMs1VZf1Vg1J.js%3Fversion%3D42%23cb%3Df1072caac8f0fc4%26domain%3Ddevelopers.facebook.com%26origin%3Dhttps%253A%252F%252Fdevelopers.facebook.com%252Ff3d228d21c088a4%26relation%3Dparent.parent&amp;container_width=678&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fpenaflamencadeturku&amp;locale=fi_FI&amp;sdk=joey&amp;show_facepile=false&amp;small_header=true&amp;tabs=timeline"
                style={{ border: 'none', visibility: 'visible', width: '340px', height: '500px' }}></iframe>
            </div>
          </aside>

        </div>

      </section>

      <Footer />

    </span>
  </Router>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titles: [],
      data: null,
      meta: null,
      ig: null
    };

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    fetch(URL + API + '/regions/data/meta?token=' + TOKEN)
      .then(function (response) {
        try {
          return response.json();
        } catch (error) {
          // TODO: parempi virhe/retry
          console.error('Pyyntö epäonnistui (meta).');
        }
      })
      .then((response) => {
        this.setState({ meta: response });
      });

    fetch(URL + API + '/collections/get/sivut?token=' + TOKEN)
      .then(function (response) {
        try {
          return response.json();
        } catch (error) {
          // TODO: parempi virhe/retry
          console.error('Pyyntö epäonnistui (page).');
        }
      })
      .then((response) => {
        let titlelist = [];
        response.entries.forEach(element => {
          const entry = ((({ title, title_slug, published }) => ({ title, title_slug, published }))(element));
          if (entry.title && entry.published === true) titlelist.push(entry);
        });

        this.setState({ titles: titlelist });
        this.setState({ data: response.entries });
      });

    fetch('https://www.instagram.com/turunflamenco/')
      .then(function (response) {
        if (response.status === 200) {
          return response.text();
        } else {
          // eslint-disable-next-line no-throw-literal
          throw 'Error loading ig content. Response: ' + response.status;
        }
      })
      .then((response) => {
        try {
          // This regexp gets widest possible dict around "profile_pic_url"
          // but inside tag <script type="text/javascript">...</script>
          const r = new RegExp('<script type="text/javascript">' +
            '([^{]+?({.*profile_pic_url.*})[^}]+?)' +
            '</script>');

          const jsonStr = response.match(r)[2];
          const rawData = JSON.parse(jsonStr);
          const igData = rawData['entry_data']['ProfilePage'][0].graphql;

          const name = (igData.user || {}).full_name || 'turunflamenco';
          const followers = ((igData.user || {}).edge_followed_by || {}).count || '100+';
          const profile_pic = (igData.user || {}).profile_pic_url_hd;
          const count = ((igData.user || {}).edge_owner_to_timeline_media || {}).count;
          const edges = [...((igData.user || {}).edge_owner_to_timeline_media || {}).edges].slice(0, 4);
          this.setState({ ig: { name, followers, profile_pic, count, edges } });

        } catch (error) {
          console.warn('Error parsing ig content: ', error);
        }
      }, (reason) => {
        console.warn(reason);
      });
  }

  render() {
    return Main(this.state)
  }
}

export default App;
