import React, { Component } from 'react';

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sec: -1
    }
  }

  componentDidMount() {
    let start = new Date(new Date().getTime() + 11000);
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(start);
      date
        ? this.setState(date, () => {
          if (this.state.sec <= 1) window.location.assign("/");
        })
        : this.clear();
    }, 1000);
  }

  componentWillUnmount() {
    this.clear();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    return { sec: diff };
  }

  clear() {
    clearInterval(this.interval);
  }

  render() {
    const countDown = this.state;
    return (
      <span>
        {(countDown.sec === -1 || countDown.sec >= 7)
          ? <span><h1>Ladataan...</h1></span>
          : <span className="error404"><h1>404 Error</h1><h3>Sivua ei löytynyt. <a href="/">Palaa etusivulle ({countDown.sec}s) &rarr;</a></h3></span>
        }
      </span>
    );
  }
}

export default Error;
