import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuIsVisible: false,
      links: null
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props && props.items) {
      this.setState({ links: props.items });
    }
  }

  toggleMenu = () => {
    this.setState({ mobileMenuIsVisible: !this.state.mobileMenuIsVisible });
  }

  render() {
    return (
      <nav id="nav-wrap">
        <button className="mobile-btn" onClick={this.toggleMenu} title="Toggle navigation">Toggle navigation</button>

        <ul id="nav" className={(this.state.mobileMenuIsVisible)
          ? 'nav visible'
          : 'nav hidden'
        }>
          {(this.state.links) &&
            this.state.links.map((link, i) =>
            <li
              key={link.title_slug}
              onClick={() => {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                // document.getElementById('content').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
                this.toggleMenu();
              }}
              className={(window.location.pathname.indexOf(link.title_slug) > 0) ? 'current' : ''}>
              <Link to={`/${link.title_slug}`}>
                {link.title}
              </Link>
            </li>
          )}
        </ul>
      </nav>
    )
  }
}

export default Navigation;
