import React, { Component } from 'react';
import { URL, PATH } from '../consts.js';
import { ReactComponent as Logo } from './Logo.svg';
import { mostBrightColor } from '../color.js';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splashImg: null,
      logoColor: 'grey'
    };
  }

  componentWillReceiveProps(props) {
    if (props.article && props.article.headerphoto) {
      this.setState({
        splashImg: `url(${URL}${PATH}${props.article.headerphoto.path})`,
        logoColor: mostBrightColor(props.article.headerphoto.colors)
      });
    }
    else if (props.meta && props.meta.splash) {
      this.setState({
        splashImg: `url(${URL}${PATH}${props.meta.splash.path})`,
        logoColor: mostBrightColor(props.meta.splash.colors)
      });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  };

  // Hide and/or change nav background based on scroll position
  handleScroll = (event) => {
    const nav = document.getElementById('nav-wrap');
    const el = document.getElementById('home');
    const h = el.offsetHeight;
    let y = window.scrollY;

    if ((y > h * .20) && (y < h) && (window.outerWidth > 768)) {
      nav.style.opacity = 0;
    } else {
      if (y < h * .20) {
        nav.classList.remove('opaque');
        nav.style.opacity = 1;
      } else {
        nav.classList.add('opaque');
        nav.style.opacity = 1;
      }
    }
  };

  handleResize = () => {
    const el = document.getElementById('home');
    const body = document.getElementsByTagName('body')[0];
    el.style.height = window.innerHeight + 'px';

    const width = (window.innerWidth && document.documentElement.clientWidth)
      ? Math.min(window.innerWidth, document.documentElement.clientWidth)
      : window.innerWidth || document.documentElement.clientWidth;

    body.style.width = width + 'px';
  }

  render() {
    // const { meta } = this.state;

    return (
      <header
        id="home"
        style={(this.state.splashImg !== null)
          ? {
            boxShadow: 'inset 0px 60px 90px -50px #171717',
            backgroundImage: this.state.splashImg,
            transitionProperty: 'background-image',
            transitionDuration: '.3s',
            transitionDelay: '0s'
          }
          : {}
        }>

        <Logo alt="logo" style={{
          fill: this.state.logoColor,
          position: 'absolute',
          minWidth: '189px',
          width: '19%',
          left: '30px',
          top: '8px',
          transitionProperty: 'fill',
          transitionDuration: '.3s',
          transitionDelay: '0s'
        }} />

        {/* <img src="images/logo.svg" /> */}

        {React.Children.only(this.props.children)}

        {/* <div className="row banner">
          <div className="banner-text"> */}
            {/* <h1 className="responsive-headline" style={{'fontSize': '90px'}}>
              <img alt="logo" style={{maxWidth: '60%'}} src="images/logo.svg" />
            </h1> */}
            {/* {(meta) ?
              <h6>
                <i className="fa fa-phone"></i>&emsp;{meta.phone}&emsp;&emsp;
                <i className="fa fa-envelope"></i>&emsp;{meta.email}&emsp;&emsp;
                <i className="fa fa-map-marker"></i>&emsp;{meta.location}&emsp;
              </h6>
              : ''
            } */}
          {/* </div>
        </div> */}
        <p className="scrolldown">
          <a href="#content" onClick={() => document.getElementById('content').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })}><i className="icon-down-circle"></i></a>
        </p>
      </header>
    )
  }
}

export default Header;
