import React, { Component } from 'react';
import { MarkdownPreview } from 'react-marked-markdown';
import ImageGallery from './ImageGallery.jsx';
import { URL, PATH, TITLE } from '../consts.js';

import Header from './Header.jsx';
import Navigation from './Navigation.jsx';
import Error from './Error.jsx';

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null
    };
  }

  componentWillReceiveProps(props) {
    if (typeof (props.data || {}).find === 'function') {
      this.setState({ article: props.data.find(article => article.title_slug === props.match.params.slug)},
      () => {
        // Scroll to content, except when page is first loaded
        // if (document.title !== TITLE)
        // window.location.hash = "content";

        // Set title
        if ((this.state.article || {}).title) {
          document.title = TITLE + ' | ' + this.state.article.title;
        } else {
          document.title = TITLE;
        }
      });
    }
  }

  render() {
    return (
      <span>
        {(this.props.meta &&
          this.props.titles &&
          this.state.article !== null &&
          this.state.article !== undefined) &&
          <Header meta={this.props.meta} article={this.state.article}>
            <Navigation items={this.props.titles} />
          </Header>
        }

        {(this.state.article !== null && this.state.article !== undefined)
          ? <section id="content" className={
            [
              this.state.article.style === 'light' ? 'resume' : 'about',
              (this.state.article.gallery || {}).length > 1 ? 'portfolio' : ''
            ].join(' ').trim()
          }>
            {(this.state.article.gallery || {}).length > 1 ?
              <div className="row">
                <div className="twelve columns collapsed">
                  <MarkdownPreview value={this.state.article.content} />
                  <ImageGallery images={this.state.article.gallery} />
                </div>
              </div>
              :
              <div className="row">
                <div className="three columns">
                  {(this.state.article.image)
                    ? <img
                        className="profile-pic"
                        src={URL + PATH + this.state.article.image.path}
                        alt={this.state.article.image.title} />
                    : ''}
                </div>
                <div className="nine columns main-col">
                  <MarkdownPreview value={this.state.article.content} />
                </div>
              </div>
            }

          </section>
          : <span>
            <div className="row">
              <div className="twelve columns">
                <p>&nbsp;</p>
                <Error />
                <p>&nbsp;</p>
              </div>
            </div>
            </span>
        }
      </span>
      )
    }
  }

  export default Page;
