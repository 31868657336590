import React, { Component } from 'react';
import { API, TOKEN, URL } from '../consts.js';
import Lightbox from 'react-images';

const placeholderImg = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIj48cGF0aCBmaWxsPSIjQ0NDIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTI4IDE1NS4yOTl2MjAxLjQwM2gyNTZWMTU1LjI5OUgxMjh6bTIzNS4zNjYgMTc5LjU2SDE0OS44MzhWMTc3LjEzOGgyMTMuNTI5bC0uMDAxIDE1Ny43MjF6Ii8+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTE2MS4zMjIgMzE3Ljg3Mmw0NC4wMTEtNDYuMjYxIDE2LjQ4NyA3LjEzNSA1Mi4wMzQtNTUuMzg1IDIwLjQ3OCAyNC40OTcgOS4yNDEtNS41ODEgNDkuOTk1IDc1LjU5NXoiLz48Y2lyY2xlIGZpbGw9IiNGRkYiIGN4PSIyMTIuMTY1IiBjeT0iMjE3LjI0NSIgcj0iMTkuMjIxIi8+PC9zdmc+';
const placeholder = (index) => ({
  _order: index,
  src: placeholderImg,
  thumbnail: placeholderImg,
  caption: 'Lataa...',
  orientation: 'square',
  srcSet: []
});

class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rawImages: props.images,
      newImages: Array.from({ length: props.images.length }, (v, i) => placeholder(i)),
      lightboxIsOpen: false,
      currentImage: 0,
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  gotoImage(index) {
    this.setState({
      currentImage: index,
    });
  }

  handleClickImage() {
    if (this.state.currentImage === this.state.newImages.length - 1) return;
    this.gotoNext();
  }

  componentDidMount() {
    this.state.rawImages.forEach(img => {
      let newImg = {
        _order: this.state.rawImages.indexOf(img),
        src: URL + img.path,
        thumbnail: '',
        caption: img.meta.title,
        orientation: 'square',
        srcSet: [
          URL + img.path + ' 2048w'
        ]
      };

      // Generate thumb
      fetch(URL + API + '/cockpit/image?token=' + TOKEN, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          src: img.meta.asset,
          m: 'thumbnail',
          w: 512,     // width
          h: 512,     // height
          q: 70,      // quality
          d: true,    // include full domain path
          b64: false  // return base64 encoded image string
        })
      })
      .then(url => url.text())
      .then(url => {
        newImg.srcSet.push(url + ' 512w');
        newImg.thumbnail = url;
        this.setState({
          // Update newImages with newImg contents
          newImages: this.state.newImages.map(
            (el) => el._order === newImg._order ? Object.assign({}, el, { ...newImg }) : el
        )});
      });
    });
  }

  renderGallery() {
    let images = this.state.newImages;
    if (!images) return;

    const gallery = images.sort((a, b) => a._order - b._order).map((item, i) => {
      return (
        <div key={i} className="columns portfolio-item">
          <div className="item-wrap">
            <a href={item.src} title="" onClick={(e) => this.openLightbox(i, e)}>
              <img alt={item.caption} src={item.thumbnail} />
              <div className="overlay">
                <div className="portfolio-item-meta">
                  <h5>{item.caption}</h5>
                </div>
              </div>
              <div className="link-icon"><i className="icon-plus"></i></div>
            </a>
          </div>
        </div>
      );
    });

    return (
      <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
        {gallery}
      </div>
    );
  }

  render() {
    return (
      <span>
        {this.renderGallery()}
        <Lightbox images={this.state.newImages}
          currentImage={this.state.currentImage}
          onClickImage={this.handleClickImage}
          isOpen={this.state.lightboxIsOpen}
          onClickThumbnail={this.gotoImage}
          onClickPrev={this.gotoPrevious}
          onClose={this.closeLightbox}
          onClickNext={this.gotoNext}
          backdropClosesModal={true}
        />
      </span>
    )
  }
}

export default ImageGallery;
